import { useEffect, useState } from "react";
import TopNav from "../components/TopNav";
import { Link, useSearchParams } from "react-router-dom";
import {
  List,
  ListItemText,
  Button,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import { Add, AddCircle } from "@mui/icons-material";
import utilStyle from "./Utils.module.css";
import { APIEmailTemplate, getAllEmailTemplates } from "../api/emailTemplates";
import SplitPageView from "../components/SplitPageView";
import EmailTemplateDetailsPage from "./EmailTemplateDetailsPage";

function EmailTemplateListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [emailTemplateData, setEmailTemplateData] = useState<
    APIEmailTemplate[]
  >([]);

  useEffect(() => {
    getAllEmailTemplates().then((v) => setEmailTemplateData(v));
  }, [id]);

  return (
    <SplitPageView
      pageTitle="Email Templates"
      contentList={
        <List style={{ padding: "0px" }}>
          <ListItemButton
            component={Link}
            to={`/email-templates`}
            style={{ border: "0.5px solid #808080" }}
            selected={id === null}
          >
            <ListItemText primary="New Email Template" />
            <ListItemIcon>
              <AddCircle />
            </ListItemIcon>
          </ListItemButton>
          {emailTemplateData.map((emailTemplate) => (
            <ListItemButton
              component={Link}
              to={`/email-templates?id=${emailTemplate.id}`}
              key={emailTemplate.id}
              style={{ border: "0.5px solid #808080" }}
              selected={emailTemplate.id === id}
            >
              <ListItemText
                primary={emailTemplate.title}
                secondary={emailTemplate.subject}
              />
            </ListItemButton>
          ))}
        </List>
      }
      mainContent={<EmailTemplateDetailsPage id={id || undefined} />}
    />
  );
}

export default EmailTemplateListPage;
