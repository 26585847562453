import { FormEvent, useEffect, useState } from "react";
import TopNav from "../components/TopNav";
import { Link, useSearchParams } from "react-router-dom";
import {
  List,
  Avatar,
  ListItemText,
  ListItemAvatar,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  Button,
  ListItemButton,
  Menu,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import { Add, Search, FilterList, AddCircle } from "@mui/icons-material";
import utilStyle from "./Utils.module.css";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  APICustomer,
  APICustomerFilter,
  getAllCustomers,
} from "../api/customers";
import SplitPageView from "../components/SplitPageView";
import CustomerDetailsPage from "./CustomerDetailsPage";

function CustomerListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [customersData, setCustomerData] = useState<APICustomer[]>([]);
  useEffect(() => {
    getAllCustomers().then((v) => setCustomerData(v));
  }, [id]);

  return (
    <SplitPageView
      pageTitle="Customers"
      contentList={
        <List style={{ padding: 0 }}>
          <ListItemButton
            component={Link}
            to={`/customers`}
            style={{ border: "0.5px solid #808080" }}
            selected={id === null}
          >
            <ListItemText primary="New Customer" />
            <ListItemIcon>
              <AddCircle />
            </ListItemIcon>
          </ListItemButton>
          {customersData.map((customer) => (
            <ListItemButton
              component={Link}
              to={`/customers?id=${customer.id}`}
              key={customer.id}
              style={{ border: "0.5px solid #808080" }}
              selected={id === customer.id}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#606060" }}>
                  {customer.firstName && customer.firstName[0].toUpperCase()}
                  {customer.lastName && customer.lastName[0].toUpperCase()}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${customer.firstName} ${customer.lastName}`}
                secondary={`${customer.addressLine1} ${customer.city}${
                  customer.city && customer.state && ", "
                }${customer.state}`}
              />
            </ListItemButton>
          ))}
        </List>
      }
      mainContent={<CustomerDetailsPage id={id || undefined} />}
    />
  );
}

export default CustomerListPage;
