import { useEffect, useState } from "react";
import TopNav from "../components/TopNav";
import { Link, useNavigate } from "react-router-dom";
import { List, ListItemText, Button, ListItemButton } from "@mui/material";
import { Add, ImportContacts } from "@mui/icons-material";
import utilStyle from "./Utils.module.css";
import { APITax, getAllTaxes } from "../api/taxes";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { APICustomer, saveCustomer } from "../api/customers";

function ContactFormImportPage() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState<readonly GridRowId[]>([]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "firstName", headerName: "First name", width: 130 },
    { field: "lastName", headerName: "Last name", width: 130 },
    {
      field: "address",
      headerName: "Address",
      width: 150,
    },
    {
      field: "city",
      headerName: "City",
      width: 80,
    },
    {
      field: "zip",
      headerName: "Zip",
      width: 45,
    },
    {
      field: "state",
      headerName: "State",
      width: 45,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 130,
    },
    {
      field: "email",
      headerName: "Email",
      width: 130,
    },
    {
      field: "voucher",
      headerName: "Voucher",
      width: 120,
    },
    {
      field: "message",
      headerName: "Message",
      width: 150,
    },
  ];

  // will come from API eventually
  const rows = [
    {
      id: "0214",
      firstName: "Example",
      lastName: "Customer",
      address: "123 Street Road",
      city: "Buffalo",
      zip: "12345",
      state: "NY",
      phone: "716-123-4567",
      email: "test@example.com",
      voucher: "",
      message: "Need a tree trimmed",
    },
    {
      id: "0216",
      firstName: "Another",
      lastName: "Person",
      address: "321 Street Road",
      city: "Amherst",
      zip: "13245",
      state: "NY",
      phone: "716-765-4321",
      email: "example@test.com",
      voucher: "",
      message: "Need a tree trimmed",
    },
  ];

  const handleImport = async () => {
    console.log(selected);
    for (const sid of selected) {
      const row = rows.find((v) => v.id === sid);
      if (row === undefined) {
        continue;
      }
      const customer: APICustomer = {
        firstName: row.firstName,
        lastName: row.lastName,
        addressLine1: row.address,
        addressLine2: "",
        city: row.city,
        zip: row.zip,
        state: row.state,
        phone: row.phone,
        phoneExt: "",
        email: row.email,
        notes: row.message,
      };
      await saveCustomer(customer);
    }
    navigate("/customers");
  };

  return (
    <div className="page">
      <TopNav />
      <div>
        <main>
          <div className={utilStyle.controlHeader}>
            <div className={utilStyle.controlHeaderButtonContainer}>
              {/* <Button variant="outlined" startIcon={<Download />}>
                Export
              </Button> */}
              <Button
                onClick={handleImport}
                color="success"
                variant="contained"
                startIcon={<ImportContacts />}
              >
                Import Selected
              </Button>
            </div>
          </div>
          <DataGrid
            columns={columns}
            rows={rows}
            pagination
            checkboxSelection
            onRowSelectionModelChange={setSelected}
          />
        </main>
      </div>
    </div>
  );
}

export default ContactFormImportPage;
