import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { APIProduct, getAllProducts } from "../api/products";
import SplitPageView from "../components/SplitPageView";
import ProductDetailsPage from "./ProductDetailsPage";

function ProductListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [productsData, setProductsData] = useState<APIProduct[]>([]);

  useEffect(() => {
    getAllProducts().then((v) => setProductsData(v));
  }, [id]);

  return (
    <SplitPageView
      pageTitle="Products"
      contentList={
        <List style={{ padding: "0px" }}>
          <ListItemButton
            component={Link}
            to={`/products`}
            style={{ border: "0.5px solid #808080" }}
            selected={id === null}
          >
            <ListItemText primary="New Product" />
            <ListItemIcon>
              <AddCircle />
            </ListItemIcon>
          </ListItemButton>
          {productsData.map((product) => (
            <ListItemButton
              component={Link}
              to={`/products?id=${product.id}`}
              key={product.id}
              style={{ border: "0.5px solid #808080" }}
              selected={product.id === id}
            >
              <ListItemText
                primary={product.title}
                secondary={`${product.description}`}
              />
            </ListItemButton>
          ))}
        </List>
      }
      mainContent={<ProductDetailsPage id={id || undefined} />}
    />
  );
}

export default ProductListPage;
