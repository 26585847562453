import {
  Breadcrumbs,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
  Link as MUILink,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import TopNav from "../components/TopNav";
import utilStyle from "./Utils.module.css";
import pageStyle from "./CustomerDetails.module.css";
import { Send } from "@mui/icons-material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { APIEmail, deleteEmail, emptyEmail, saveEmail } from "../api/emails";
import {
  APIEmailTemplate,
  getAllEmailTemplates,
  getEmailTemplate,
} from "../api/emailTemplates";
import { APIProposal, getAllProposals, getProposal } from "../api/proposals";
import { APICustomer, getAllCustomers, getCustomer } from "../api/customers";
import Handlebars from "handlebars";

function EmailDetailsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const refId = searchParams.get("refId") || "";
  const refType = searchParams.get("refType") || "";
  const templateId = searchParams.get("templateId") || "";
  const navigate = useNavigate();

  const [email, setEmailData] = useState<APIEmail>(emptyEmail());
  const [customers, setCustomers] = useState<APICustomer[]>([]);
  const [proposals, setProposals] = useState<APIProposal[]>([]);
  const [templates, setTemplates] = useState<APIEmailTemplate[]>([]);

  useEffect(() => {
    getAllCustomers().then((v) => setCustomers(v));
    getAllProposals().then((v) => setProposals(v));
  }, []);

  useEffect(() => {
    getAllEmailTemplates().then((v) => {
      const f = v.filter((et) => et.refType == refType);
      setTemplates(f);
    });
  }, [refType]);

  useEffect(() => {
    (async () => {
      const template = await getEmailTemplate(templateId || undefined);
      let refVal: any = {};
      if (template.refType == "proposal") {
        refVal = await getProposal(refId || undefined);
      }
      if (template.refType == "customer") {
        refVal = await getCustomer(refId || undefined);
      }

      const newEmail = emptyEmail();

      newEmail.to = refVal.email || "";
      newEmail.from = template.from;
      newEmail.attachments = template.attachments;
      newEmail.refType = template.refType;
      newEmail.refId = refId || undefined;

      newEmail.subject = Handlebars.compile(template.subject)(refVal);
      newEmail.body = Handlebars.compile(template.body)(refVal);
      newEmail.templateId = templateId || undefined;
      setEmailData(newEmail);
    })();
  }, [templateId, refId]);

  const [isModified, setIsModified] = useState(false);

  // Handle changes
  const changeEmail = (change: Partial<APIEmail>) => {
    setSearchParams((prev) => {
      if (change.refType) {
        prev.set("refType", change.refType);
      }
      if (change.refId) {
        prev.set("refId", change.refId);
      }
      if (change.templateId) {
        prev.set("templateId", change.templateId);
      }
      return prev;
    });

    setEmailData({ ...email, ...change });
  };

  const canSave = () => {
    // TODO: Add validation
    return true;
  };

  const handleSave = async () => {
    const t = await saveEmail({ ...email, generatedAt: new Date().getTime() });
    navigate(`/emails?id=${t.id}`);
  };

  return (
    <div className={pageStyle.customerDetailPanel}>
      <div>
        <form
          className={pageStyle.editCustomerForm}
          noValidate
          autoComplete="off"
        >
          <FormControl>
            <InputLabel>Reference Type</InputLabel>
            <Select
              value={refType}
              onChange={(e) => changeEmail({ refType: e.target.value })}
              label="Reference Type"
            >
              <MenuItem value="proposal">Proposal</MenuItem>
              <MenuItem value="customer">Customer</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Reference</InputLabel>
            <Select
              value={refId}
              onChange={(e) => changeEmail({ refId: e.target.value })}
              label="Reference"
            >
              {refType == "customer" &&
                customers.map((r) => (
                  <MenuItem key={r.id} value={r.id}>
                    {r.firstName} {r.lastName}
                  </MenuItem>
                ))}
              {refType == "proposal" &&
                proposals.map((r) => (
                  <MenuItem key={r.id} value={r.id}>
                    {r.firstName} {r.lastName}{" "}
                    {new Date(r.createdAt).toLocaleDateString()}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Template</InputLabel>
            <Select
              value={email.templateId}
              onChange={(e) => changeEmail({ templateId: e.target.value })}
              label="Template"
            >
              {templates.map((t) => (
                <MenuItem key={t.id} value={t.id}>
                  {t.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="toInput">To</InputLabel>
            <OutlinedInput
              value={email.to}
              onChange={(e) => changeEmail({ to: e.target.value })}
              id="toInput"
              label="To"
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="fromInput">From</InputLabel>
            <OutlinedInput
              value={email.from}
              onChange={(e) => changeEmail({ from: e.target.value })}
              id="fromInput"
              label="From"
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="subjectInput">Subject</InputLabel>
            <OutlinedInput
              value={email.subject}
              onChange={(e) => changeEmail({ subject: e.target.value })}
              id="subjectInput"
              label="Subject"
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="bodyInput">Body</InputLabel>
            <OutlinedInput
              value={email.body}
              onChange={(e) => changeEmail({ body: e.target.value })}
              multiline
              minRows={5}
              id="bodyInput"
              label="Body"
            />
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={email.attachments}
                onChange={(e) => changeEmail({ attachments: e.target.checked })}
              />
            }
            label="Attachments"
          />
        </form>
        <div className={utilStyle.controlHeader}>
          <div className={utilStyle.controlHeaderButtonContainer}>
            <Button
              color="success"
              variant="contained"
              startIcon={<Send />}
              onClick={handleSave}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailDetailsPage;
