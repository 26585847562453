import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function AuthPage() {
  const [searchParams] = useSearchParams();
  const to = searchParams.get("to") || "/";

  const { isLoading, getAccessTokenSilently } = useAuth0();
  const nav = useNavigate();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    // set the token
    getAccessTokenSilently()
      .then((token) => {
        // set token
        sessionStorage.setItem("token", token);

        // forward to application
        nav(to);
      })
      .catch(() => {
        nav("/login");
      });
  }, [isLoading, getAccessTokenSilently, nav, to]);

  return <></>;
}

export default AuthPage;
