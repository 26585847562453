import { Q } from "@nozbe/watermelondb";
import { database } from "..";
import Email from "../model/email";
import EmailAttachment from "../model/emailAttachment";
import EmailTemplate from "../model/emailTemplate";

export type APIEmail = {
  id?: string;
  to: string;
  from: string;
  subject: string;
  body: string;
  attachments: boolean;
  refType?: string;
  refId?: string;
  templateId?: string;
  generatedAt?: number
  sentAt?: number
  attachmentFiles?: APIEmailAttachment[];
};

export type APIEmailAttachment = {
  id?: string;
  emailId?: string;
  file: string;
};

export function emptyEmailAttachment(): APIEmailAttachment {
  return {
    file: "",
  };
}

export function emptyEmail(): APIEmail {
  return {
    to: "",
    from: "",
    subject: "",
    body: "",
    attachments: false,
    refType: "",
    refId: "",
    templateId: "",
  };
}

function dbEmailAttachmentToAPIEmailAttachment(
  ea: EmailAttachment
): APIEmailAttachment {
  return {
    id: ea.id,
    emailId: ea.email?.id || "",
    file: ea.file || "",
  };
}

function dbEmailToAPIEmail(et: Email, ea: EmailAttachment[]): APIEmail {
  return {
    id: et.id,
    to: et.to || "",
    from: et.from || "",
    subject: et.subject || "",
    body: et.body || "",
    attachments: et.attachments || false,
    refType: et.refType || undefined,
    refId: et.refId|| undefined,
    templateId: et.emailTemplate?.id || undefined,
    generatedAt: et.generatedAt || undefined,
    sentAt: et.sentAt || undefined,
    attachmentFiles: ea.map(dbEmailAttachmentToAPIEmailAttachment),
  };
}

export async function getEmail(emailID?: string): Promise<APIEmail> {
  if (!emailID) {
    return emptyEmail();
  }
  const email = await database.get<Email>("emails").find(emailID);
  const emailAttachments = await database
    .get<EmailAttachment>("email_attachments")
    .query(Q.where("email_id", emailID));
  return dbEmailToAPIEmail(email, emailAttachments);
}

async function saveEmailAttachment(
  attachment: APIEmailAttachment,
  emailId?: string
) {
  const email = emailId
    ? await database.get<Email>("emails").find(emailId)
    : null;

  const databaseWrite = (ea: EmailAttachment) => {
    const e: any = ea.email;
    e.set(email);
    ea.file = attachment.file;
  };
  if (!attachment.id) {
    await database.write(async () => {
      await database.get<Email>("emails").create(databaseWrite);
    });
  } else {
    await database.write(async () => {
      const savedEmail = await database
        .get<Email>("emails")
        .find(attachment.id || "");
      await savedEmail.update(databaseWrite);
    });
  }
}

export async function saveEmail(email: APIEmail): Promise<APIEmail> {
  const tr = email.templateId
    ? await database
        .get<EmailTemplate>("email_templates")
        .find(email.templateId)
    : undefined;
  const databaseWrite = (t: Email) => {
    t.to = email.to;
    t.from = email.from;
    t.subject = email.subject;
    t.body = email.body;
    t.attachments = email.attachments;
    t.refType = email.refType;
    t.refId = email.refId;
    const etr: any = t.emailTemplate;
    etr.set(tr);
    t.generatedAt = email.generatedAt;
  };
  let savedEmailID: string | undefined = email.id;
  if (!email.id) {
    await database.write(async () => {
      const savedEmail = await database
        .get<Email>("emails")
        .create(databaseWrite);
      savedEmailID = savedEmail.id;
    });
  } else {
    await database.write(async () => {
      const savedEmail = await database
        .get<Email>("emails")
        .find(email.id || "");
      await savedEmail.update(databaseWrite);
    });
  }
  if (email.attachmentFiles) {
    await Promise.all(
      email.attachmentFiles.map((v) => saveEmailAttachment(v, savedEmailID))
    );
  }

  return getEmail(savedEmailID);
}

export async function deleteEmail(emailId: string): Promise<void> {
  await database.write(async () => {
    const email = await database.get<Email>("emails").find(emailId);
    await email.markAsDeleted();
  });
}

export async function getAllEmails(): Promise<APIEmail[]> {
  const emailCollection = database.get<Email>("emails");
  let query = emailCollection.query();
  const dbEmails: Email[] = await query.fetch();

  const proposals: APIEmail[] = await Promise.all(
    dbEmails.map(async (e: Email) => {
      const emailAttachmentCollection =
        database.get<EmailAttachment>("email_attachments");
      const dbLines = await emailAttachmentCollection
        .query(Q.where("email_id", e.id))
        .fetch();
      return dbEmailToAPIEmail(e, dbLines);
    })
  );
  return proposals;
}
