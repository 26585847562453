import { Model } from "@nozbe/watermelondb";
import {
  text,
  date,
  readonly,
  field,
  relation,
} from "@nozbe/watermelondb/decorators";
import Email from "./email";

export default class EmailAttachment extends Model {
  static table = "email_attachments";

  @relation("emails", "email_id") email?: Email;
  @text("file") file?: string;

  @readonly @date("created_at") createdAt?: number;
  @readonly @date("updated_at") updatedAt?: number;
}
