import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { apiRequest } from "../utils";

function GmailAuthPage() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const nav = useNavigate();
  const token = sessionStorage.getItem("token");
  apiRequest
    .post(
      "/setup/gmail/set",
      { code },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(() => {
      nav("/settings");
    });

  return <></>;
}

export default GmailAuthPage;
