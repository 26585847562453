import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Database } from "@nozbe/watermelondb";
import LokiJSAdapter from "@nozbe/watermelondb/adapters/lokijs";
import schema from "./model/schema";
import Customer from "./model/customer";
import Photo from "./model/photo";
import Tax from "./model/tax";
import Product from "./model/product";
import Proposal from "./model/proposal";
import ProposalLineItem from "./model/proposalLineItem";
import Setting from "./model/settings";
import { Auth0Provider } from "@auth0/auth0-react";
import EmailTemplate from "./model/emailTemplate";
import Email from "./model/email";
import EmailAttachment from "./model/emailAttachment";

const adapter = new LokiJSAdapter({
  schema,
  // migrations,
  useWebWorker: false,
  useIncrementalIndexedDB: true,

  onQuotaExceededError: (error) => {},
  onSetUpError: (error) => {},
  extraIncrementalIDBOptions: {
    onDidOverwrite: () => {},
    onversionchange: () => {},
  },
});

const database = new Database({
  adapter,
  modelClasses: [
    Customer,
    Photo,
    Tax,
    Product,
    Proposal,
    ProposalLineItem,
    Setting,
    EmailTemplate,
    Email,
    EmailAttachment,
  ],
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/auth`,
        audience: "TestAPI",
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

export { database };
