import { useEffect, useState } from "react";
import TopNav from "../components/TopNav";
import { Link, useSearchParams } from "react-router-dom";
import {
  List,
  ListItemText,
  Button,
  ListItemButton,
  Typography,
  Divider,
  ListItemIcon,
} from "@mui/material";
import { Add, AddCircle } from "@mui/icons-material";
import utilStyle from "./Utils.module.css";
import { APIEmail, getAllEmails, getEmail } from "../api/emails";
import emailStyle from "./Email.module.css";
import SplitPageView from "../components/SplitPageView";
import EmailDetailsPage from "./EmailEditPage";

function EmailListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [emailData, setEmailData] = useState<APIEmail[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<APIEmail | undefined>(
    undefined
  );

  useEffect(() => {
    getAllEmails().then((v) => setEmailData(v));
  }, [id]);

  useEffect(() => {
    if (!id) {
      setSelectedEmail(undefined);
      return;
    }
    getEmail(id).then((v) => setSelectedEmail(v));
  }, [id]);

  return (
    <SplitPageView
      pageTitle="Emails"
      contentList={
        <List style={{ padding: 0 }}>
          <ListItemButton
            component={Link}
            to={`/emails`}
            style={{ border: "0.5px solid #808080" }}
            selected={id === null}
          >
            <ListItemText primary="New Email" />
            <ListItemIcon>
              <AddCircle />
            </ListItemIcon>
          </ListItemButton>
          {emailData.map((email) => (
            <ListItemButton
              component={Link}
              to={`/emails?id=${email.id}`}
              key={email.id}
              selected={email.id === id}
              style={{ border: "0.5px solid #808080" }}
            >
              <ListItemText
                primary={email.subject}
                secondary={email.to}
                style={{ flexGrow: 1 }}
              />

              <ListItemText
                secondary="4/2/25"
                style={{ textAlign: "right", flexGrow: 0 }}
              />
            </ListItemButton>
          ))}
        </List>
      }
      mainContent={
        selectedEmail ? (
          <>
            <Typography>Subject: {selectedEmail.subject}</Typography>
            <Typography>To: {selectedEmail.to}</Typography>
            <Typography>From: {selectedEmail.from}</Typography>
            <Typography>
              Generated at: {selectedEmail.generatedAt?.toLocaleString()}
            </Typography>
            <Typography>
              Sent at:{" "}
              {selectedEmail.sentAt?.valueOf()
                ? selectedEmail.sentAt.toLocaleString()
                : "Pending"}
            </Typography>
            <Typography>
              Refrence:{" "}
              {
                <>
                  {selectedEmail.refType === "customer" && (
                    <Link to={`/customers/details?id=${selectedEmail.refId}`}>
                      Customer
                    </Link>
                  )}
                  {selectedEmail.refType === "proposal" && (
                    <Link to={`/proposals/details?id=${selectedEmail.refId}`}>
                      Proposal
                    </Link>
                  )}
                </>
              }
            </Typography>
            <Divider />
            <Typography>{selectedEmail.body}</Typography>
          </>
        ) : (
          <EmailDetailsPage />
        )
      }
    />
  );
}

export default EmailListPage;
