import {
  Breadcrumbs,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
  Link as MUILink,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import TopNav from "../components/TopNav";
import utilStyle from "./Utils.module.css";
import pageStyle from "./CustomerDetails.module.css";
import { Delete, Save } from "@mui/icons-material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  APIEmailTemplate,
  deleteEmailTemplate,
  emptyEmailTemplate,
  getEmailTemplate,
  saveEmailTemplate,
  str2RefType,
} from "../api/emailTemplates";

function EmailTemplateDetailsPage({ id }: { id?: string }) {
  const navigate = useNavigate();

  const [emailTemplate, setEmailTemplateData] = useState<APIEmailTemplate>(
    emptyEmailTemplate()
  );

  useEffect(() => {
    getEmailTemplate(id || undefined).then((v) => setEmailTemplateData(v));
  }, [id]);

  const [isModified, setIsModified] = useState(false);

  const [selectedField, setSelectedField] = useState<{
    element: EventTarget & (HTMLTextAreaElement | HTMLInputElement);
    field: string;
  } | null>(null);

  const tags = {
    proposal: [
      "firstName",
      "lastName",
      "phone",
      "phoneExt",
      "email",
      "addressLine1",
      "addressLine1",
      "addressLine2",
      "city",
      "state",
      "zip",
      "serviceAddressLine1",
      "serviceAddressLine2",
      "serviceCity",
      "serviceState",
      "serviceZip",
      "serviceNotes",
      "taxName",
      "taxRate",
      "subtotalAmount",
      "taxAmount",
      "totalAmount",
      "terms",
    ],
    customer: [
      "firstName",
      "lastName",
      "phone",
      "phoneExt",
      "email",
      "addressLine1",
      "addressLine1",
      "addressLine2",
      "city",
      "state",
      "zip",
      "notes",
    ],
  };

  // Handle changes
  const changeEmailTemplate = (change: Partial<APIEmailTemplate>) => {
    setIsModified(true);
    setEmailTemplateData({ ...emailTemplate, ...change });
  };

  const canSave = () => {
    // TODO: Add validation
    return isModified;
  };

  const handleSave = async () => {
    const t = await saveEmailTemplate(emailTemplate);
    setEmailTemplateData(t);
    navigate(`/email-templates?id=${t.id}`);
    setIsModified(false);
  };

  const handleDelete = async () => {
    if (id) {
      await deleteEmailTemplate(id);
    }
    navigate("/email-templates");
  };

  const insertTag = (tag: string) => {
    if (selectedField === null) {
      return;
    }
    const start = selectedField.element.selectionStart || undefined;
    const end = selectedField.element.selectionEnd || undefined;

    if (selectedField.field === "subject") {
      changeEmailTemplate({
        subject:
          emailTemplate.subject.slice(0, start) +
          tag +
          emailTemplate.subject.slice(end),
      });
    }

    if (selectedField.field === "body") {
      changeEmailTemplate({
        body:
          emailTemplate.body.slice(0, start) +
          tag +
          emailTemplate.body.slice(end),
      });
    }

    selectedField.element.focus();
  };

  return (
    <div className={pageStyle.customerDetailPanel}>
      <div>
        <form
          className={pageStyle.editCustomerForm}
          noValidate
          autoComplete="off"
        >
          <FormControl>
            <InputLabel htmlFor="titleInput">Title</InputLabel>
            <OutlinedInput
              value={emailTemplate.title}
              onChange={(e) => changeEmailTemplate({ title: e.target.value })}
              onFocus={(e) => setSelectedField(null)}
              id="titleInput"
              label="Title"
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="subjectInput">Subject</InputLabel>
            <OutlinedInput
              value={emailTemplate.subject}
              onChange={(e) => changeEmailTemplate({ subject: e.target.value })}
              onFocus={(e) =>
                setSelectedField({ element: e.target, field: "subject" })
              }
              id="subjectInput"
              label="Subject"
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="fromInput">From</InputLabel>
            <OutlinedInput
              value={emailTemplate.from}
              onChange={(e) => changeEmailTemplate({ from: e.target.value })}
              onFocus={(e) => setSelectedField(null)}
              id="fromInput"
              label="From"
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="bodyInput">Body</InputLabel>
            <OutlinedInput
              value={emailTemplate.body}
              onChange={(e) => changeEmailTemplate({ body: e.target.value })}
              onFocus={(e) =>
                setSelectedField({ element: e.target, field: "body" })
              }
              multiline
              minRows={5}
              id="bodyInput"
              label="Body"
            />
          </FormControl>
          <FormControl>
            <InputLabel>Referenced Type</InputLabel>
            <Select
              value={emailTemplate.refType}
              onChange={(e) =>
                changeEmailTemplate({
                  refType: str2RefType(e.target.value),
                })
              }
              onFocus={(e) => setSelectedField(null)}
              label="Referenced Type"
            >
              <MenuItem value={"proposal"}>Proposal</MenuItem>
              <MenuItem value={"customer"}>Customer</MenuItem>
            </Select>
          </FormControl>
          {emailTemplate.refType !== undefined && (
            <div className={utilStyle.controlHeader}>
              <div className={utilStyle.controlHeaderButtonContainer}>
                {tags[emailTemplate.refType].map((v) => {
                  return (
                    <Button
                      variant="outlined"
                      onClick={() => insertTag(`{{${v}}}`)}
                    >
                      {v}
                    </Button>
                  );
                })}
              </div>
            </div>
          )}
          <FormControlLabel
            control={
              <Switch
                checked={emailTemplate.attachments}
                onChange={(e) =>
                  changeEmailTemplate({ attachments: e.target.checked })
                }
                onFocus={(e) => setSelectedField(null)}
              />
            }
            label="Attachments"
          />
        </form>
        <div className={utilStyle.controlHeader}>
          <div className={utilStyle.controlHeaderButtonContainer}>
            <Button
              color="success"
              variant="contained"
              startIcon={<Save />}
              disabled={!canSave()}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              color="error"
              variant="outlined"
              startIcon={<Delete />}
              disabled={!id}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailTemplateDetailsPage;
