import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormControlLabel,
  Switch,
} from "@mui/material";
import utilStyle from "./Utils.module.css";
import pageStyle from "./CustomerDetails.module.css";
import { Delete, Save } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  APIProduct,
  deleteProduct,
  emptyProduct,
  getProduct,
  saveProduct,
} from "../api/products";

function ProductDetailsPage({ id }: { id?: string }) {
  const navigate = useNavigate();

  const [product, setProduct] = useState<APIProduct>(emptyProduct());
  useEffect(() => {
    getProduct(id || undefined).then((v) => setProduct(v));
  }, [id]);

  const [isModified, setIsModified] = useState(false);

  // Handle changes
  const changeProduct = (change: Partial<APIProduct>) => {
    setIsModified(true);
    setProduct({ ...product, ...change });
  };

  const canSave = () => {
    // TODO: Add validation
    return isModified;
  };

  const handleSave = async () => {
    const p = await saveProduct(product);
    setProduct(p);
    navigate(`/products?id=${p.id}`);
    setIsModified(false);
  };

  const handleDelete = async () => {
    if (id) {
      await deleteProduct(id);
    }
    navigate("/products");
  };

  return (
    <div className={pageStyle.customerDetailPanel}>
      <div>
        <form
          className={pageStyle.editCustomerForm}
          noValidate
          autoComplete="off"
        >
          <FormControl>
            <InputLabel htmlFor="titleInput">Title</InputLabel>
            <OutlinedInput
              value={product.title}
              onChange={(e) => changeProduct({ title: e.target.value })}
              id="titleInput"
              label="Title"
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="descriptionInput">Description</InputLabel>
            <OutlinedInput
              value={product.description}
              onChange={(e) => changeProduct({ description: e.target.value })}
              multiline
              minRows={3}
              id="descriptionInput"
              label="Description"
            />
          </FormControl>
          <FormControl>
            <InputLabel>Price</InputLabel>
            <OutlinedInput
              type="number"
              value={product.price}
              onChange={(e) => changeProduct({ price: Number(e.target.value) })}
              label="Price"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={product.taxable}
                onChange={(e) => changeProduct({ taxable: e.target.checked })}
              />
            }
            label="Taxable"
          />
        </form>
        <div className={utilStyle.controlHeader}>
          <div className={utilStyle.controlHeaderButtonContainer}>
            <Button
              color="success"
              variant="contained"
              startIcon={<Save />}
              disabled={!canSave()}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              color="error"
              variant="outlined"
              startIcon={<Delete />}
              disabled={!id}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetailsPage;
