import { appSchema, tableSchema } from "@nozbe/watermelondb";

export default appSchema({
  version: 1,
  tables: [
    tableSchema({
      name: "customers",
      columns: [
        { name: "first_name", type: "string" },
        { name: "last_name", type: "string" },
        { name: "phone", type: "string" },
        { name: "phone_ext", type: "string" },
        { name: "email", type: "string" },
        { name: "address_line_1", type: "string" },
        { name: "address_line_2", type: "string" },
        { name: "city", type: "string" },
        { name: "state", type: "string" },
        { name: "zip", type: "string" },
        { name: "notes", type: "string" },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "photos",
      columns: [
        { name: "customer_id", type: "string" },
        { name: "photo_data", type: "string" },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "taxes",
      columns: [
        { name: "title", type: "string" },
        { name: "percent_rate", type: "number" },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "email_templates",
      columns: [
        { name: "title", type: "string" },
        { name: "from", type: "string" },
        { name: "subject", type: "string" },
        { name: "body", type: "string" },
        { name: "ref_type", type: "string" },
        { name: "attachments", type: "boolean" },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "email_attachments",
      columns: [
        { name: "email_id", type: "string" },
        { name: "file", type: "string" },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "emails",
      columns: [
        { name: "to", type: "string" },
        { name: "from", type: "string" },
        { name: "subject", type: "string" },
        { name: "body", type: "string" },
        { name: "ref_type", type: "string" },
        { name: "ref_id", type: "string" },
        { name: "attachments", type: "boolean" },
        { name: "template_id", type: "string" },
        { name: "generated_at", type: "number" },
        { name: "sent_at", type: "number" },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "products",
      columns: [
        { name: "title", type: "string" },
        { name: "description", type: "string" },
        { name: "price", type: "number" },
        { name: "taxable", type: "boolean" },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "proposals",
      columns: [
        { name: "customer_id", type: "string" },
        { name: "customer_first_name", type: "string" },
        { name: "customer_last_name", type: "string" },
        { name: "customer_phone", type: "string" },
        { name: "customer_phone_ext", type: "string" },
        { name: "customer_email", type: "string" },
        { name: "customer_address_line_1", type: "string" },
        { name: "customer_address_line_2", type: "string" },
        { name: "customer_city", type: "string" },
        { name: "customer_state", type: "string" },
        { name: "customer_zip", type: "string" },
        { name: "service_address_line_1", type: "string" },
        { name: "service_address_line_2", type: "string" },
        { name: "service_city", type: "string" },
        { name: "service_state", type: "string" },
        { name: "service_zip", type: "string" },
        { name: "service_notes", type: "string" },
        { name: "tax_name", type: "string" },
        { name: "tax_rate", type: "number" },
        { name: "tax_id", type: "string" },
        { name: "subtotal_amount", type: "number" },
        { name: "tax_amount", type: "number" },
        { name: "total_amount", type: "number" },
        { name: "status", type: "string" },
        { name: "invoice_date", type: "number", isOptional: true },
        { name: "invoice_due_date", type: "number", isOptional: true },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
        { name: "signature", type: "string" },
        { name: "terms", type: "string" },
      ],
    }),
    tableSchema({
      name: "proposal_line_items",
      columns: [
        { name: "proposal_id", type: "string" },
        { name: "product_id", type: "string" },
        { name: "product_name", type: "string" },
        { name: "product_notes", type: "string" },
        { name: "quantity", type: "number" },
        { name: "unit_price", type: "number" },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "settings",
      columns: [
        { name: "key", type: "string" },
        { name: "value", type: "string" },
      ],
    }),
  ],
});
