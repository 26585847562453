import { database } from "..";
import Product from "../model/product";

export type APIProduct = {
  id?: string;
  title: string;
  description: string;
  price: number;
  taxable: boolean;
};

export function emptyProduct(): APIProduct {
  return {
    title: "",
    description: "",
    price: 0,
    taxable: true,
  };
}

function dbProductToAPIProduct(p: Product): APIProduct {
  return {
    id: p.id,
    title: p.title || "",
    description: p.description || "",
    price: p.price || 0,
    taxable: p.taxable || false,
  };
}

export async function getProduct(productID?: string): Promise<APIProduct> {
  if (!productID) {
    return emptyProduct();
  }
  const product = await database.get<Product>("products").find(productID);
  return dbProductToAPIProduct(product);
}

export async function saveProduct(product: APIProduct): Promise<APIProduct> {
  const databaseWrite = (p: Product) => {
    p.title = product.title;
    p.description = product.description;
    p.price = product.price;
    p.taxable = product.taxable;
  };
  let savedProductID: string | undefined = product.id;
  if (!product.id) {
    await database.write(async () => {
      const savedProduct = await database
        .get<Product>("products")
        .create(databaseWrite);
      savedProductID = savedProduct.id;
    });
  } else {
    await database.write(async () => {
      const savedProduct = await database
        .get<Product>("products")
        .find(product.id || "");
      await savedProduct.update(databaseWrite);
    });
  }
  return getProduct(savedProductID);
}

export async function deleteProduct(productID: string): Promise<void> {
  await database.write(async () => {
    const product = await database.get<Product>("products").find(productID);
    await product.markAsDeleted();
  });
}

export async function getAllProducts(): Promise<APIProduct[]> {
  const productCollection = database.get<Product>("products");
  let query = productCollection.query();
  const deProducts: Product[] = await query.fetch();
  const products: APIProduct[] = deProducts.map(dbProductToAPIProduct);
  return products;
}
