import { Model } from "@nozbe/watermelondb";
import { text, date, readonly, field } from "@nozbe/watermelondb/decorators";

export default class EmailTemplate extends Model {
  static table = "email_templates";

  @text("title") title?: string;
  @text("from") from?: string;
  @text("subject") subject?: string;
  @text("body") body?: string;

  @field("attachments") attachments?: boolean;
  @text("ref_type") refType?: string;

  @readonly @date("created_at") createdAt?: number;
  @readonly @date("updated_at") updatedAt?: number;
}
