import { Model } from "@nozbe/watermelondb";
import {
  text,
  date,
  readonly,
  field,
  relation,
} from "@nozbe/watermelondb/decorators";
import EmailTemplate from "./emailTemplate";

export default class Email extends Model {
  static table = "emails";

  @text("to") to?: string;
  @text("from") from?: string;
  @text("subject") subject?: string;
  @text("body") body?: string;

  @field("attachments") attachments?: boolean;
  @text("ref_type") refType?: string;
  @text("ref_id") refId?: string;

  @relation("email_templates", "template_id") emailTemplate?: EmailTemplate;

  @date("generated_at") generatedAt?: number;
  @date("sent_at") sentAt?: number;


  @readonly @date("created_at") createdAt?: number;
  @readonly @date("updated_at") updatedAt?: number;
}
