import { database } from "..";
import Setting from "../model/settings";
import { Q } from "@nozbe/watermelondb";

export type APIAddress = {
  name?: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
};

export function emptyAddress() {
  return {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
  };
}

export async function getDefaultTerms(): Promise<string> {
  const settings = await database
    .get<Setting>("settings")
    .query(Q.where("key", "terms"))
    .fetch();
  if (settings.length !== 0 && settings[0].value) {
    return settings[0].value;
  }
  return "";
}

export async function setDefaultTerms(terms: string): Promise<void> {
  await database.write(async () => {
    const termsSetting = await database
      .get<Setting>("settings")
      .query(Q.where("key", "terms"))
      .fetch();
    if (termsSetting.length !== 0 && termsSetting[0]) {
      termsSetting[0].update((s) => {
        s.value = terms;
      });
    } else {
      await database.get<Setting>("settings").create((s) => {
        s.key = "terms";
        s.value = terms;
      });
    }
  });
}

export async function getBusinessLogo(): Promise<string> {
  return "";
}

export async function setLogo(): Promise<void> {}

export async function getBusinessAddress(): Promise<APIAddress> {
  return {
    name: "Lifetree WNY",
    addressLine1: "123 Street Road",
    addressLine2: "",
    city: "Buffalo",
    state: "NY",
    zip: "12345",
  };
}
