import { useEffect, useState } from "react";
import TopNav from "../components/TopNav";
import { Link, useSearchParams } from "react-router-dom";
import {
  List,
  ListItemText,
  Button,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import { Add, AddCircle, Create, Edit } from "@mui/icons-material";
import utilStyle from "./Utils.module.css";
import { APITax, getAllTaxes } from "../api/taxes";
import SplitPageView from "../components/SplitPageView";
import TaxDetailsPage from "./TaxDetailsPage";

function TaxListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [taxesData, setTaxesData] = useState<APITax[]>([]);

  useEffect(() => {
    getAllTaxes().then((v) => setTaxesData(v));
  }, [id]);

  return (
    <SplitPageView
      pageTitle="Taxes"
      contentList={
        <List style={{ padding: "0px" }}>
          <ListItemButton
            component={Link}
            to={`/taxes`}
            style={{ border: "0.5px solid #808080" }}
            selected={id === null}
          >
            <ListItemText primary="New Tax" />
            <ListItemIcon>
              <AddCircle />
            </ListItemIcon>
          </ListItemButton>
          {taxesData.map((tax) => (
            <ListItemButton
              component={Link}
              to={`/taxes?id=${tax.id}`}
              key={tax.id}
              style={{ border: "0.5px solid #808080" }}
              selected={tax.id === id}
            >
              <ListItemText
                primary={tax.title}
                secondary={`${tax.percentRate}%`}
              />
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      }
      mainContent={<TaxDetailsPage id={id || undefined} />}
    />
  );
}

export default TaxListPage;
