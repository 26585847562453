import { database } from "..";
import Customer from "../model/customer";
import Product from "../model/product";
import Proposal from "../model/proposal";
import ProposalLineItem from "../model/proposalLineItem";
import Tax from "../model/tax";
import { Q } from "@nozbe/watermelondb";
import { getDefaultTerms } from "./settings";

export type APIProposalStatus = "LEAD" | "QUOTED" | "WON" | "DONE";

export function strToStatus(s: string): APIProposalStatus {
  switch (s) {
    case "LEAD":
      return "LEAD";
    case "QUOTED":
      return "QUOTED";
    case "WON":
      return "WON";
    case "DONE":
      return "DONE";
  }
  throw Error("invalid status string");
}

export type APIProposalLine = {
  id?: string;
  productId: string;
  productName: string;
  productNotes: string;
  quantity: number;
  unitPrice: number;
};

export function emptyProposalLine(): APIProposalLine {
  return {
    productId: "",
    productName: "",
    productNotes: "",
    quantity: 1,
    unitPrice: 0,
  };
}

export type APIProposal = {
  id?: string;
  customerId: string;
  firstName: string;
  lastName: string;
  phone: string;
  phoneExt: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  serviceAddressLine1: string;
  serviceAddressLine2: string;
  serviceCity: string;
  serviceState: string;
  serviceZip: string;
  serviceNotes: string;

  lines: APIProposalLine[];

  taxName: string;
  taxRate: number;
  taxId: string;

  subtotalAmount: number;
  taxAmount: number;
  totalAmount: number;

  status: APIProposalStatus;
  invoiceDate?: number;
  invoiceDueDate?: number;

  terms: string;
  signature: string;

  createdAt: number;
  updatedAt: number;
};

export function emptyProposal(): APIProposal {
  return {
    customerId: "",
    firstName: "",
    lastName: "",
    phone: "",
    phoneExt: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    serviceAddressLine1: "",
    serviceAddressLine2: "",
    serviceCity: "",
    serviceState: "NY",
    serviceZip: "",
    serviceNotes: "",

    lines: [],

    taxName: "",
    taxRate: 0.0,
    taxId: "",
    subtotalAmount: 0,
    taxAmount: 0,
    totalAmount: 0,

    status: "QUOTED",
    invoiceDate: 0,
    invoiceDueDate: 0,

    terms: "",
    signature: "",

    createdAt: 0,
    updatedAt: 0,
  };
}

function dbProposalLineToAPIProposalLine(
  pl: ProposalLineItem
): APIProposalLine {
  return {
    id: pl.id,
    productId: pl.product?.id || "",
    productName: pl.productName || "",
    productNotes: pl.productNotes || "",
    quantity: pl.quantity || 0,
    unitPrice: pl.unitPrice || 0,
  };
}

function dbProposalToAPIProposal(
  p: Proposal,
  pl: ProposalLineItem[]
): APIProposal {
  return {
    id: p.id,
    customerId: p.customer?.id || "",
    firstName: p.firstName || "",
    lastName: p.lastName || "",
    phone: p.phone || "",
    phoneExt: p.phoneExt || "",
    email: p.email || "",
    addressLine1: p.addressLine1 || "",
    addressLine2: p.addressLine2 || "",
    city: p.city || "",
    state: p.state || "",
    zip: p.zip || "",
    serviceAddressLine1: p.serviceAddressLine1 || "",
    serviceAddressLine2: p.serviceAddressLine2 || "",
    serviceCity: p.serviceCity || "",
    serviceState: p.serviceState || "",
    serviceZip: p.serviceZip || "",
    serviceNotes: p.serviceNotes || "",

    lines: pl.map(dbProposalLineToAPIProposalLine),

    taxName: p.taxName || "",
    taxRate: p.taxRate || 0.0,
    taxId: p.tax?.id || "",
    subtotalAmount: p.subtotalAmount || 0,
    taxAmount: p.taxAmount || 0,
    totalAmount: p.totalAmount || 0,

    status: strToStatus(p.status || "QUOTED"),
    invoiceDate: p.invoiceDate || 0,
    invoiceDueDate: p.invoiceDueDate || 0,

    terms: p.terms || "",
    signature: p.signature || "",

    createdAt: p.createdAt || 0,
    updatedAt: p.updatedAt || 0,
  };
}

export async function getProposal(
  proposalId?: string,
  customerId?: string,
  taxId?: string
): Promise<APIProposal> {
  if (!proposalId) {
    const newProposal = emptyProposal();
    if (customerId) {
      const c = await database.get<Customer>("customers").find(customerId);
      newProposal.customerId = c.id;
      newProposal.firstName = c.firstName || newProposal.firstName;
      newProposal.lastName = c.lastName || newProposal.lastName;
      newProposal.email = c.email || newProposal.email;
      newProposal.phone = c.phone || newProposal.phone;
      newProposal.phoneExt = c.phoneExt || newProposal.phoneExt;
      newProposal.addressLine1 = c.addressLine1 || newProposal.addressLine1;
      newProposal.addressLine2 = c.addressLine2 || newProposal.addressLine2;
      newProposal.city = c.city || newProposal.city;
      newProposal.state = c.state || newProposal.state;
      newProposal.zip = c.zip || newProposal.zip;
    }
    if (taxId) {
      const t = await database.get<Tax>("taxes").find(taxId);
      newProposal.taxId = t.id;
      newProposal.taxName = t.title || newProposal.taxName;
      newProposal.taxRate = t.percentRate || newProposal.taxRate;
    }
    newProposal.terms = await getDefaultTerms();
    return newProposal;
  }
  const proposal = await database.get<Proposal>("proposals").find(proposalId);
  const proposalLines = await database
    .get<ProposalLineItem>("proposal_line_items")
    .query(Q.where("proposal_id", proposal.id));
  return dbProposalToAPIProposal(proposal, proposalLines);
}

async function saveProposalLine(line: APIProposalLine, proposalId?: string) {
  const proposal = proposalId
    ? await database.get<Proposal>("proposals").find(proposalId)
    : null;
  const product = line.productId
    ? await database.get<Product>("products").find(line.productId)
    : null;

  const databaseWrite = (p: ProposalLineItem) => {
    const prod: any = p.product;
    prod.set(product);

    const prop: any = p.proposal;
    prop.set(proposal);

    p.productName = line.productName;
    p.productNotes = line.productNotes;
    p.quantity = line.quantity;
    p.unitPrice = line.unitPrice;
  };
  if (!line.id) {
    await database.write(async () => {
      await database
        .get<ProposalLineItem>("proposal_line_items")
        .create(databaseWrite);
    });
  } else {
    await database.write(async () => {
      const savedProposal = await database
        .get<ProposalLineItem>("proposal_line_items")
        .find(line.id || "");
      await savedProposal.update(databaseWrite);
    });
  }
}

async function deleteProposalLine(lineId: string) {
  await database.write(async () => {
    const proposalLine = await database
      .get<ProposalLineItem>("proposal_line_items")
      .find(lineId);
    await proposalLine.markAsDeleted();
  });
}

export async function saveProposal(
  proposal: APIProposal,
  removedLineIds: string[]
): Promise<APIProposal> {
  const customer = proposal.customerId
    ? await database.get<Customer>("customers").find(proposal.customerId)
    : null;
  const tax = proposal.taxId
    ? await database.get<Tax>("taxes").find(proposal.taxId)
    : null;

  const databaseWrite = (p: Proposal) => {
    const c: any = p.customer;
    c.set(customer);
    p.firstName = proposal.firstName;
    p.lastName = proposal.lastName;
    p.phone = proposal.phone;
    p.phoneExt = proposal.phoneExt;
    p.email = proposal.email;
    p.addressLine1 = proposal.addressLine1;
    p.addressLine2 = proposal.addressLine2;
    p.city = proposal.city;
    p.state = proposal.state;
    p.zip = proposal.zip;
    p.serviceAddressLine1 = proposal.serviceAddressLine1;
    p.serviceAddressLine2 = proposal.serviceAddressLine2;
    p.serviceCity = proposal.serviceCity;
    p.serviceState = proposal.serviceState;
    p.serviceZip = proposal.serviceZip;
    p.serviceNotes = proposal.serviceNotes;

    p.status = proposal.status;
    p.invoiceDate = proposal.invoiceDate;
    p.invoiceDueDate = proposal.invoiceDueDate;

    p.terms = proposal.terms;
    p.signature = proposal.signature;

    const t: any = p.tax;
    t.set(tax);
    p.taxName = proposal.taxName;
    p.taxRate = proposal.taxRate;

    p.subtotalAmount = Number(proposal.subtotalAmount);
    p.taxAmount = Number(proposal.taxAmount);
    p.totalAmount = Number(proposal.totalAmount);
  };
  let savedProposalID: string | undefined = proposal.id;
  if (!proposal.id) {
    await database.write(async () => {
      const savedProposal = await database
        .get<Proposal>("proposals")
        .create(databaseWrite);
      savedProposalID = savedProposal.id;
    });
  } else {
    await database.write(async () => {
      const savedProposal = await database
        .get<Proposal>("proposals")
        .find(proposal.id || "");
      await savedProposal.update(databaseWrite);
    });
  }
  await Promise.all(
    proposal.lines.map((v) => saveProposalLine(v, savedProposalID))
  );
  await Promise.all(removedLineIds.map(deleteProposalLine));
  return getProposal(savedProposalID);
}

export async function deleteProposal(proposalId: string): Promise<void> {
  await database.write(async () => {
    const proposal = await database.get<Proposal>("proposals").find(proposalId);
    await proposal.markAsDeleted();
  });
}

export async function getAllProposals(): Promise<APIProposal[]> {
  const proposalCollection = database.get<Proposal>("proposals");
  let query = proposalCollection.query();
  const dbProposals: Proposal[] = await query.fetch();

  const proposals: APIProposal[] = await Promise.all(
    dbProposals.map(async (p: Proposal) => {
      const proposalLineCollection = database.get<ProposalLineItem>(
        "proposal_line_items"
      );
      const dbLines = await proposalLineCollection
        .query(Q.where("proposal_id", p.id))
        .fetch();
      return dbProposalToAPIProposal(p, dbLines);
    })
  );
  return proposals;
}
