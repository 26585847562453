import { database } from "..";
import EmailTemplate from "../model/emailTemplate";

export function str2RefType(s?: String): "customer" | "proposal" | undefined {
  switch (s) {
    case "customer":
      return "customer";
    case "proposal":
      return "proposal";
  }
  return undefined;
}

export type APIEmailTemplate = {
  id?: string;
  title: string;
  from: string;
  subject: string;
  body: string;
  attachments: boolean;
  refType?: "customer" | "proposal";
};

export function emptyEmailTemplate(): APIEmailTemplate {
  return {
    title: "",
    from: "",
    subject: "",
    body: "",
    attachments: false,
  };
}

function dbEmailTemplateToAPIEmailTemplate(
  et: EmailTemplate
): APIEmailTemplate {
  return {
    id: et.id,
    title: et.title || "",
    from: et.from || "",
    subject: et.subject || "",
    body: et.body || "",
    attachments: et.attachments || false,
    refType: str2RefType(et.refType),
  };
}

export async function getEmailTemplate(
  emailTemplateID?: string
): Promise<APIEmailTemplate> {
  if (!emailTemplateID) {
    return emptyEmailTemplate();
  }
  const emailTemplate = await database
    .get<EmailTemplate>("email_templates")
    .find(emailTemplateID);
  return dbEmailTemplateToAPIEmailTemplate(emailTemplate);
}

export async function saveEmailTemplate(
  emailTemplate: APIEmailTemplate
): Promise<APIEmailTemplate> {
  const databaseWrite = (t: EmailTemplate) => {
    t.title = emailTemplate.title;
    t.from = emailTemplate.from;
    t.subject = emailTemplate.subject;
    t.body = emailTemplate.body;
    t.attachments = emailTemplate.attachments;
    t.refType = emailTemplate.refType;
  };
  let savedEmailTemplateID: string | undefined = emailTemplate.id;
  if (!emailTemplate.id) {
    await database.write(async () => {
      const savedEmailTemplate = await database
        .get<EmailTemplate>("email_templates")
        .create(databaseWrite);
      savedEmailTemplateID = savedEmailTemplate.id;
    });
  } else {
    await database.write(async () => {
      const savedEmailTemplate = await database
        .get<EmailTemplate>("email_templates")
        .find(emailTemplate.id || "");
      await savedEmailTemplate.update(databaseWrite);
    });
  }
  return getEmailTemplate(savedEmailTemplateID);
}

export async function deleteEmailTemplate(
  emailTemplateId: string
): Promise<void> {
  await database.write(async () => {
    const emailTemplate = await database
      .get<EmailTemplate>("email_templates")
      .find(emailTemplateId);
    await emailTemplate.markAsDeleted();
  });
}

export async function getAllEmailTemplates(): Promise<APIEmailTemplate[]> {
  const emailTemplateCollection =
    database.get<EmailTemplate>("email_templates");
  let query = emailTemplateCollection.query();
  const dbEmailTemplates: EmailTemplate[] = await query.fetch();
  const emailTemplates: APIEmailTemplate[] = dbEmailTemplates.map(
    dbEmailTemplateToAPIEmailTemplate
  );
  return emailTemplates;
}
