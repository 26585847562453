import { BrowserRouter, Route, Routes } from "react-router-dom";
import CustomerListPage from "./pages/CustomerListPage";
import CustomerDetailsPage from "./pages/CustomerDetailsPage";
import ProposalListPage from "./pages/ProposalListPage";
import ProposalDetailsPage from "./pages/ProposalDetailsPage";
import ProposalPDFPage from "./pages/ProposalPDFPage";
import Redirect from "./utils/Redirect";
import PhotosPage from "./pages/PhotosPage";
import TaxListPage from "./pages/TaxListPage";
import ProductListPage from "./pages/ProductListPage";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import ActivityListPage from "./pages/ActivityListPage";
import SettingsPage from "./pages/SettingsPage";
import LoginPage from "./pages/LoginPage";
import AuthPage from "./pages/AuthPage";
import QuickbooksPage from "./pages/Quickbooks";
import EmailTemplateListPage from "./pages/EmailTemplateListPage";
import EmailTemplateDetailsPage from "./pages/EmailTemplateDetailsPage";
import EmailDetailsPage from "./pages/EmailEditPage";
import EmailListPage from "./pages/EmaiListPage";
import GmailAuthPage from "./pages/GmailAuthPage";
import ContactFormImportPage from "./pages/ContactFormImport";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Redirect to="/customers" />} />
          <Route path="/customers">
            <Route path="" element={<CustomerListPage />} />
          </Route>
          <Route path="/proposals">
            <Route path="" element={<ProposalListPage />} />
            <Route path="pdf" element={<ProposalPDFPage />} />
          </Route>
          <Route path="/activity" />
          <Route path="/photos">
            <Route path="details" element={<PhotosPage />} />
          </Route>
          <Route path="/products">
            <Route path="" element={<ProductListPage />} />{" "}
          </Route>
          <Route path="/taxes">
            <Route path="" element={<TaxListPage />} />
          </Route>
          <Route path="/email-templates">
            <Route path="" element={<EmailTemplateListPage />} />
          </Route>
          <Route path="/emails">
            <Route path="" element={<EmailListPage />} />
            <Route path="details" element={<EmailDetailsPage />} />
          </Route>
          <Route path="/activity">
            <Route path="" element={<ActivityListPage />} />
          </Route>
          <Route path="/settings">
            <Route path="" element={<SettingsPage />} />
          </Route>
          <Route path="/quickbooks">
            <Route path="" element={<QuickbooksPage />} />
          </Route>
          <Route path="/contact-form">
            <Route path="" element={<ContactFormImportPage />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/gmail-auth" element={<GmailAuthPage />} />
          <Route path="*" />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
