import TopNav from "../components/TopNav";
import splitStyle from "./SplitPage.module.css";
import { ReactNode } from "react";

function SplitPageView({
  contentList,
  mainContent,
  pageTitle,
}: {
  contentList: ReactNode;
  mainContent: ReactNode;
  pageTitle: string;
}) {
  return (
    <div className={`page ${splitStyle.page}`}>
      <TopNav pageTitle={pageTitle} />
      <main className={splitStyle.split}>
        <div className={splitStyle.sidebar}>{contentList}</div>
        <div className={splitStyle.viewerContainer}>
          <div className={splitStyle.viewer}>{mainContent}</div>
        </div>
      </main>
    </div>
  );
}

export default SplitPageView;
