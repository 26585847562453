import { useEffect, useState } from "react";
import TopNav from "../components/TopNav";
import { Link, useSearchParams } from "react-router-dom";
import {
  List,
  Avatar,
  ListItemText,
  ListItemAvatar,
  FormControl,
  Button,
  ListItemButton,
  Chip,
  ListItemIcon,
  Menu,
  Checkbox,
  ListItem,
  Slider,
} from "@mui/material";
import { AddCircle, FilterList } from "@mui/icons-material";
import utilStyle from "./Utils.module.css";
import { formatMoney } from "../utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { APIProposal, getAllProposals } from "../api/proposals";
import SplitPageView from "../components/SplitPageView";
import ProductDetailsPage from "./ProductDetailsPage";
import ProposalDetailsPage from "./ProposalDetailsPage";

function ProposalListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [proposalsData, setProposalsData] = useState<APIProposal[]>([]);

  useEffect(() => {
    getAllProposals().then((v) => setProposalsData(v));
  }, [id]);

  return (
    <SplitPageView
      pageTitle="Proposals"
      contentList={
        <List style={{ padding: 0 }}>
          <ListItemButton
            component={Link}
            to={`/proposals`}
            style={{ border: "0.5px solid #808080" }}
            selected={id === null}
          >
            <ListItemText primary="New Proposal" />
            <ListItemIcon>
              <AddCircle />
            </ListItemIcon>
          </ListItemButton>
          {proposalsData.map((proposal) => (
            <ListItemButton
              component={Link}
              to={`/proposals?id=${proposal.id}`}
              key={proposal.id}
              style={{ border: "0.5px solid #808080" }}
              selected={proposal.id === id}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#606060" }}>
                  {proposal.firstName && proposal.firstName[0].toUpperCase()}
                  {proposal.lastName && proposal.lastName[0].toUpperCase()}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${proposal.firstName} ${proposal.lastName}`}
                secondary={`${proposal.addressLine1} ${proposal.city}, ${proposal.state}`}
              />
              <Chip
                label={formatMoney(proposal.totalAmount || 0)}
                color="success"
                variant="outlined"
              />
            </ListItemButton>
          ))}
        </List>
      }
      mainContent={<ProposalDetailsPage id={id || undefined} />}
    />
  );
}

export default ProposalListPage;
