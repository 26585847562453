import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { APIProposal, emptyProposal, getProposal } from "../api/proposals";
import { APIAddress, emptyAddress, getBusinessAddress } from "../api/settings";
import { formatMoney } from "../utils";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontSize: 12,
  },
  split: {
    flexDirection: "row",
  },

  addressBox: {
    margin: 10,
    padding: 10,
    width: "50%",
  },

  section: {
    margin: 10,
    padding: 10,
  },

  terms: {
    margin: 10,
    padding: 10,
  },

  proposalLine: {
    flexDirection: "row",
  },

  proposalLineInfo: {
    width: "100%",
    border: 0.5,
    padding: 5,
  },

  proposalLineQty: {
    width: 30,
    border: 0.5,
    padding: 5,
  },

  proposalLinePrice: {
    width: 50,
    border: 0.5,
    padding: 5,
  },

  signatureLine: {
    width: "100%",
    borderBottom: 1,
  },

  signatureImg: {
    height: 30,
    width: 150,
  },
});

export default function ProposalPDF({ invoiceId }: { invoiceId: string }) {
  const [proposal, setProposal] = useState<APIProposal>(emptyProposal());
  const [companyDetails, setCompanyDetails] = useState<APIAddress>(
    emptyAddress()
  );
  useEffect(() => {
    getProposal(invoiceId || undefined).then((v) => setProposal(v));
    getBusinessAddress().then((v) => setCompanyDetails(v));
  }, [invoiceId]);

  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.split}>
          <View style={styles.addressBox}>
            <Text>Logo here</Text>
          </View>
          <View style={styles.addressBox}>
            {companyDetails.name && <Text>{companyDetails.name}</Text>}
            <Text>{companyDetails.addressLine1}</Text>
            <Text>{companyDetails.addressLine2}</Text>
            <Text>
              {companyDetails.city}, {companyDetails.state} {companyDetails.zip}
            </Text>
          </View>
        </View>
        <View style={styles.split}>
          <View style={styles.addressBox}>
            <Text>Billing Address</Text>
            <Text>
              {proposal.firstName} {proposal.lastName}
            </Text>
            <Text>{proposal.addressLine1}</Text>
            <Text>{proposal.addressLine2}</Text>
            <Text>
              {proposal.city}, {proposal.state} {proposal.zip}
            </Text>
          </View>
          <View style={styles.addressBox}>
            <Text>Shipping Address</Text>
            <Text>
              {proposal.firstName} {proposal.lastName}
            </Text>
            <Text>{proposal.serviceAddressLine1}</Text>
            <Text>{proposal.serviceAddressLine2}</Text>
            <Text>
              {proposal.serviceCity}, {proposal.serviceState}{" "}
              {proposal.serviceZip}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          {proposal.lines.map((l) => {
            return (
              <View style={styles.proposalLine}>
                <View style={styles.proposalLineInfo}>
                  <Text>{l.productName}</Text>
                  <Text>{l.productNotes}</Text>
                </View>
                <Text style={styles.proposalLineQty}>{l.quantity}</Text>
                <Text style={styles.proposalLinePrice}>
                  {formatMoney(l.unitPrice)}
                </Text>
              </View>
            );
          })}
        </View>
        <View style={styles.terms}>
          <Text>{proposal.terms}</Text>
        </View>
        <View style={styles.section}>
          {proposal.signature ? (
            <Image style={styles.signatureImg} src={proposal.signature}></Image>
          ) : (
            <Text style={styles.signatureLine}>X</Text>
          )}
        </View>
      </Page>
    </Document>
  );
}
