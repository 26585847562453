import { useSearchParams } from "react-router-dom";
import ProposalPDF from "../components/ProposalPDF";
import { PDFViewer } from "@react-pdf/renderer";
import TopNav from "../components/TopNav";
import { useEffect, useState } from "react";
import { APIProposal, emptyProposal, getProposal } from "../api/proposals";

export default function ProposalPDFPage() {
  const [searchParams, _] = useSearchParams();
  const id = searchParams.get("id") || "";

  return (
    <div className="page">
      <TopNav />
      <div>
        <PDFViewer width="100%" height="100%">
          <ProposalPDF invoiceId={id} />
        </PDFViewer>
      </div>
    </div>
  );
}
