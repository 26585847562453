import { useEffect, useState } from "react";
import TopNav from "../components/TopNav";
import { Link } from "react-router-dom";
import {
  List,
  ListItemText,
  FormControl,
  Button,
  ListItemButton,
  Menu,
  ListItem,
  Chip,
  ListItemIcon,
  Typography,
} from "@mui/material";
import {
  FilterList,
  AccessTime,
  Assignment,
  Photo as MUIPhoto,
} from "@mui/icons-material";
import utilStyle from "./Utils.module.css";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { formatMoney } from "../utils";
import pageStyle from "./CustomerDetails.module.css";
import { APIActivity, APIActivityDay, getActivities } from "../api/activities";

function ActivityListPage() {
  // filters
  const [createdStart, setCreatedStart] = useState<number | undefined>(
    undefined
  );
  const [createdEnd, setCreatedEnd] = useState<number | undefined>(undefined);

  // Dropdown menus
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const filterOpen = !!filterAnchorEl;

  const [activities, setActivities] = useState<APIActivityDay[]>([]);

  // Fetch Data
  useEffect(() => {
    getActivities()
      .then((res) => setActivities(res))
      .catch();
  }, []);

  return (
    <div className="page">
      <TopNav pageTitle="Activity" />
      <div>
        <main>
          <div className={utilStyle.controlHeader}>
            <div className={utilStyle.controlHeaderButtonContainer}>
              <Button
                onClick={(e) => setFilterAnchorEl(e.currentTarget)}
                variant="outlined"
                startIcon={<FilterList />}
              >
                Filter
              </Button>
              <Menu
                anchorEl={filterAnchorEl}
                open={filterOpen}
                onClose={() => setFilterAnchorEl(null)}
                sx={{ width: 400, maxWidth: "100%" }}
              >
                <List sx={{ width: 400, maxWidth: "100%" }}>
                  <List>
                    <ListItemText sx={{ paddingLeft: 2 }}>Created</ListItemText>
                    <ListItem>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            onChange={(e) =>
                              setCreatedStart(e ? e.unix() * 1000 : undefined)
                            }
                            value={
                              createdStart ? dayjs(createdStart) : undefined
                            }
                            label="Start Date"
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </ListItem>
                    <ListItem>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl fullWidth>
                          <DatePicker
                            onChange={(e) =>
                              setCreatedEnd(e ? e.unix() * 1000 : undefined)
                            }
                            value={createdEnd ? dayjs(createdEnd) : undefined}
                            label="End Date"
                          />
                        </FormControl>
                      </LocalizationProvider>
                    </ListItem>
                  </List>
                  <List>
                    <ListItemText sx={{ paddingLeft: 2 }}>
                      Last Activity
                    </ListItemText>
                    <ListItem>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker label="Start Date" />
                        </LocalizationProvider>
                      </FormControl>
                    </ListItem>
                    <ListItem>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl fullWidth>
                          <DatePicker label="End Date" />
                        </FormControl>
                      </LocalizationProvider>
                    </ListItem>
                  </List>
                </List>
              </Menu>
            </div>
          </div>
          {activities.map((day) => (
            <div>
              <Typography variant="subtitle1">{day.day}</Typography>
              <List>
                {day.activities.map((activity: APIActivity) => {
                  switch (activity.type) {
                    case "proposal":
                      return (
                        <ListItemButton
                          key={activity.id}
                          component={Link}
                          to={`/proposals/details?id=${activity.id}`}
                        >
                          <ListItemIcon>
                            <Assignment />
                          </ListItemIcon>
                          <ListItemText
                            primary="Proposal"
                            secondary={formatMoney(activity.totalAmount)}
                          />
                          <Chip
                            label={activity.status}
                            color="primary"
                            variant="filled"
                          />
                        </ListItemButton>
                      );
                    case "salesCall":
                      return (
                        <ListItemButton key={activity.id}>
                          <ListItemIcon>
                            <AccessTime />
                          </ListItemIcon>
                          <ListItemText
                            primary="Sales Call"
                            secondary={`Thursday, June 6, 2024, 11:00 AM`}
                          />
                        </ListItemButton>
                      );
                    case "photo":
                      return (
                        <ListItemButton
                          component={Link}
                          to={`/photos/details?id=${activity.id}`}
                          key={activity.id}
                        >
                          <ListItemIcon>
                            <MUIPhoto />
                          </ListItemIcon>
                          <img
                            className={pageStyle.customerPhoto}
                            src={activity.photoData}
                            style={{ maxWidth: "400px" }}
                            alt="customer sketch"
                          />
                        </ListItemButton>
                      );
                  }
                  return <></>;
                })}
              </List>
            </div>
          ))}
        </main>
      </div>
    </div>
  );
}

export default ActivityListPage;
